<template>
  <el-dialog v-model="dialogFormVisible" :title="title" width="800px" @close="close">
    <div class="flexBox">
      <el-tag style="margin-right: 15px" type="danger">
        *导入用户标准模版
      </el-tag>
      <el-button type="primary" @click="downLoadTemplate()">模版下载</el-button>
    </div>

    <el-form style="margin-top: 20px">
      <el-form-item label="文件上传">
        <el-upload ref="upLoadBox" accept=".xlsx" action="#" :auto-upload="false" class="upload-demo" drag :file-list="fileList" :on-change="changeFile">
          <!--
      :on-remove="handleRemove" -->
          <el-icon class="el-icon--upload"><upload-filled /></el-icon>
          <div class="el-upload__text">
            拖拽文件到这里或者
            <em>点击上传</em>
          </div>
          <template #tip>
            <div class="el-upload__tip">Excel 文件 大小不超过100M</div>
          </template>
        </el-upload>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button :loading="upLoading" type="primary" @click="save">
        确 定
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  reactive,
  toRefs,
  ref,
  onMounted,
} from 'vue'
import { getEnterpriseMarketingImportUser } from '@/api/exhibitionManage'
// 引入下拉数选择
export default defineComponent({
  name: 'UserManagementEdit',
  components: {},
  props: {
    eventId: {
      type: String,
      default: '',
    },
  },
  emits: ['fetch-data'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()
    // 自定义手机验证
    const subLabel = ref('选择展会')
    const state = reactive({
      formRef: null,
      upLoadBox: null,
      form: {},
      identityType: '',
      file: null,
      fileList: [],
      departmentData: [],
      rules: {
        channelName: [
          { required: true, trigger: 'blur', message: '请输入渠道名称' },
        ],
        remarks: [
          { required: true, trigger: 'blur', message: '请输入渠道备注' },
        ],
      },
      title: '',
      dialogFormVisible: false,
      roleList: [],
      // HOLD_MEETING EXHIBITION FORUM
      exhList: [
        {
          label: '会议',
          value: 'HOLD_MEETING',
        },
        {
          label: '展会',
          value: 'EXHIBITION',
        },
        {
          label: '论坛',
          value: 'FORUM',
        },
      ],
      subExhList: [],
      identityList: [],
      meetingCode: '', // 二级菜单传递code
      meetingType: '', // 一级菜单传递type
      upLoading: false,
    })
    const showEdit = async (row) => {
      state.title = '群体导入'
      state.dialogFormVisible = true
    }
    const upLoadFile = (e) => {
      console.log(e)
    }

    const fetchMeetData = (eventId) => {
      // getIdentityList(eventId).then((res) => {
      //   console.log(res)
      //   // state.identityList = res.data
      // })
    }
    const downLoadTemplate = (e) => {
      console.log(e)
      window.open(
        `https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-admin/%E7%BE%A4%E7%BB%84%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx`
      )
    }

    const changeFile = (val) => {
      console.log(val)
      // console.log("选择文件成功");
      let nameArray = val.name.split('.')
      if (nameArray[nameArray.length - 1] === 'xlsx') {
        // file保存选择的文件
        state.file = val.raw
      } else {
        this.$message({
          message: '表格格式错误,请确认表格内容并重新上传',
          type: 'warning',
          duration: 2000,
        })
      }
    }
    const close = () => {
      state.fileList = []
      state.form = {}
      state.dialogFormVisible = false
    }
    const save = () => {
      if (state.upLoading) {
        return false
      }
      state.upLoading = true
      let formData = new FormData()
      formData.append('file', state.file)
      getEnterpriseMarketingImportUser(formData)
        .then((res) => {
          console.log(res, state.upLoadBox)
          proxy.$baseMessage('导入完成', 'success', 'vab-hey-message-success')
          state.dialogFormVisible = false
          state.upLoading = false
          emit('fetch-data')
        })
        .catch(() => {
          proxy.$baseMessage('导入失败', 'error', 'vab-hey-message-error')
          state.dialogFormVisible = false
          state.upLoading = false
          emit('fetch-data')
        })
    }

    return {
      ...toRefs(state),
      showEdit,
      changeFile,
      fetchMeetData,
      downLoadTemplate,
      close,
      upLoadFile,
      save,
    }
  },
})
</script>

<style lang="scss" scoped>
.flexBox {
  display: flex;
  align-content: center;
  justify-content: right;
}
</style>
